import React from "react"
import styled from "styled-components"
import Seo from "../components/Seo"
import { rhythm } from "../utilities/typography"
import NavigationLayout, { Content } from "../components/layout/mainLayout"
import { graphql } from "gatsby"
import Project from "../components/Project"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FooSVG from "../svg/wavy2.svg"

const Backdrop = () => {
  return (
    <div
      css={`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: ${rhythm(30)};
        z-index: -1;
      `}
    >
      <div
        css={`
          background: linear-gradient(
            to bottom,
            #00338d ${(props) => props.theme.navbarHeight},
            #275abb,
            #3168cb 100%
          );
          height: ${rhythm(25)};
        `}
      />
      <FooSVG
        css={`
          color: #3168cb;
        `}
      />
    </div>
  )
}

const Header = styled(Container)`
  color: white;
  margin-bottom: ${rhythm(2)};

  & > *:first-child {
    margin-bottom: ${rhythm(1)};
  }

  & > h6 {
    margin-bottom: ${rhythm(2)};
  }
`

const ProjectsPage = ({
  data: {
    allContentfulProjekt: { nodes },
  },
}) => {
  return (
    <NavigationLayout>
      <Seo title="Projekte" />
      <Backdrop />
      <Content>
        <Container maxWidth={"lg"}>
          <Header>
            <Typography align={"center"} variant={"h1"}>
              Projekte
            </Typography>
            <Typography align={"center"} variant={"subtitle1"}>
              Der LIONS Club Achern beteiligt sich an folgenden, karitativen
              Projekten des LIONS Club Deutschland.
            </Typography>
          </Header>
          <Grid container spacing={8}>
            {nodes.map((node) => {
              return (
                <Grid item key={node.title}>
                  <Project {...node} />
                </Grid>
              )
            })}
          </Grid>
        </Container>
      </Content>
    </NavigationLayout>
  )
}

export const query = graphql`
  query projects {
    allContentfulProjekt {
      nodes {
        heroImage {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          title
          description
        }
        title
        description {
          description
        }
      }
    }
  }
`
export default ProjectsPage
