import React from "react"
import { rhythm } from "../utilities/typography"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

const Project = ({ heroImage, description, title }) => {
  return (
    <Card
      css={`
        display: flex;
        min-height: ${rhythm(15)};
        flex-direction: column;
        @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
          height: ${rhythm(15)};
          flex-direction: row;
        }
      `}
    >
      <CardMedia
        css={`
          min-width: 50%;
        `}
      >
        <GatsbyImage
          css={`
            height: 100%;
          `}
          image={getImage(heroImage)}
          title={heroImage.title}
          alt={heroImage.description}
        />
      </CardMedia>
      <CardContent>
        <Typography variant={"h5"} gutterBottom>
          {title}
        </Typography>
        <Typography
          style={{
            fontSize: "1rem",
            lineHeight: 1.6,
            color: "rgba(14, 30, 37, 0.8)",
          }}
        >
          {description.description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Project
